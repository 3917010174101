<template>
  <div>
    <!-- <van-nav-bar title="授权登录" /> -->
    <div>
      <div class="we-flex-col we-justify-content-center margin20b">
        <van-image width="100" height="100" round :src="imgUrl" class="imgcenter" />
        <p class="we-font-size-xl text-align-c">{{realName}}</p>
      </div>
      <div>
          <van-cell title="个人信息" is-link to="/information" >
          <template #icon >
            <van-image width="15" fit="contain" :src="require('@assets/images/info.png')" class="we-common-mr8" />
          </template></van-cell>
          <van-cell title="安全管理" is-link to="/security" ><template #icon>
            <van-image width="15" fit="contain" :src="require('@assets/images/safe.png')"  class="we-common-mr8"/>
          </template></van-cell>
          <van-cell title="关于我们" value="v1.0.0" is-link to="/about" >
          <template #icon>
            <van-image width="15" fit="contain" :src="require('@assets/images/about.png')" class="we-common-mr8" />
          </template></van-cell>
        
      </div>
      <div class="position">
        <div class="buttton-lg" @click="handleOut">注销登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Field, Image as VanImage, Button } from "vant";
import { getUserinfo } from "@api/user";
import { logout } from "@api/system";

export default {
  name: "personal",
  data() {
    return {
      imgUrl: "",
      realName: "",
    };
  },

  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },

  methods: {
    //获取个人信息
    ajax_getUserInfo() {
      getUserinfo().then((res) => {
        if (res.respCode == 200) {
          this.imgUrl =
            res.result.headImage === null
              ? "https://img.yzcdn.cn/vant/cat.jpeg"
              : res.result.headImage;
          this.realName = res.result.realName;
        } else {
          this.$toast(res.respMsg);
        }
      });
    },
    //注销登录
    handleOut() {
      logout().then((res) => {
        if (res.respCode == 200) {
          this.$toast("已退出");
          window.localStorage.setItem("isLogin", false);
          // window.localStorage.removeItem("token");
          // window.localStorage.removeItem("userinfo");
          // window.localStorage.removeItem("scanurl");
         window.localStorage.clear();
          this.$router.push({name:'login'})
        } else {
          this.$toast(res.respMsg);
        }
      });
    },
  },
  mounted() {
    this.ajax_getUserInfo();
  },
};
</script>
<style lang="less" scope>
.imgcenter {
  margin: 20px auto 0;
}
.position {
  position: absolute;
  bottom: 75px;
  left: 15px;
  right:15px;
}
.we-common-mr8{
  margin-right: 8px;
}
</style>